@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('./fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('./fonts/Roboto-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
